import CopyToClipboardButton from '@components/CopyToClipboardButton';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import type { TemplateVariable } from '@services/scheduledTexts/types';

interface TagTableProps {
  tags: TemplateVariable[];
}

const TagTable = ({ tags }: TagTableProps) => (
  <div className="w-full">
    <span className="text-sm text-gray-500">Parameters</span>
    <Table removeWrapper aria-label="SMS Tags">
      <TableHeader>
        <TableColumn>Description</TableColumn>
        <TableColumn>Tag</TableColumn>
        <TableColumn>
          <span></span>
        </TableColumn>
      </TableHeader>
      <TableBody>
        {tags.map(({ key, description }, idx) => (
          <TableRow key={idx}>
            <TableCell>{description}</TableCell>
            <TableCell>{key}</TableCell>
            <TableCell>
              <CopyToClipboardButton text={key} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default TagTable;
