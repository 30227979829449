import { CopyAll, Edit, Textsms } from '@mui/icons-material';
import { Chip, getKeyValue, Tooltip } from '@nextui-org/react';
import { useAsyncList } from '@react-stately/data';
import { getScheduleTexts } from '@services/scheduledTexts';
import type { ScheduleTextConfiguration } from '@services/scheduledTexts/types';
import dayjs from 'dayjs';
import type { ChangeEvent, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useScheduledTextsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recurring, setRecurring] = useState(true);
  const [recurringCount, setRecurringCount] = useState(0);
  const [nonRecurringCount, setNonRecurringCount] = useState(0);
  const [filter, setFilter] = useState('');

  const navigate = useNavigate();

  const list = useAsyncList<ScheduleTextConfiguration>({
    load: async () => {
      const res = await getScheduleTexts(filter, recurring);
      setRecurringCount(res.recurringCount);
      setNonRecurringCount(res.nonRecurringCount);

      setIsLoading(false);
      return {
        items: res.scheduledTexts,
      };
    },
  });

  const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const goToNewScheduledText = () => {
    navigate('/scheduled-texts/new');
  };

  useEffect(() => {
    setIsLoading(true);
    list.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, recurring]);

  const renderRecurringCell = useCallback(
    (scheduleText: ScheduleTextConfiguration, columnKey: Key) => {
      switch (columnKey) {
        case 'type':
          return (
            <Chip color="secondary" variant="flat" size="sm">
              {getKeyValue(scheduleText, columnKey.toString())}
            </Chip>
          );
        case 'status':
          const status = getKeyValue(scheduleText, columnKey.toString());
          return (
            <Chip
              color={status === 'ACTIVE' ? 'success' : status === 'PAUSED' ? 'warning' : 'danger'}
              variant="flat"
              size="sm"
            >
              {status}
            </Chip>
          );
        case 'lastRunAt':
        case 'firstSendAt':
          return getKeyValue(scheduleText, columnKey.toString())
            ? dayjs(getKeyValue(scheduleText, columnKey.toString())).format('MMM D, YYYY hh:mm a')
            : 'N/A';
        case 'actions':
          return (
            <div className="flex gap-2">
              <Tooltip content="Edit">
                <Edit
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/scheduled-texts/${scheduleText.id}`)}
                />
              </Tooltip>
              <Tooltip content="Duplicate">
                <CopyAll
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/scheduled-texts/new?duplicate=${scheduleText.id}`)}
                />
              </Tooltip>
              <Tooltip content={scheduleText.messageTemplate} size="sm" className="max-w-[300px]">
                <Textsms color="secondary" />
              </Tooltip>
            </div>
          );

        default:
          return getKeyValue(scheduleText, columnKey.toString());
      }
    },
    [navigate],
  );

  const renderNonRecurringCell = useCallback(
    (scheduleText: ScheduleTextConfiguration, columnKey: Key) => {
      switch (columnKey) {
        case 'type':
          return (
            <Chip color="secondary" variant="flat" size="sm">
              {getKeyValue(scheduleText, columnKey.toString())}
            </Chip>
          );
        case 'status':
          const status = getKeyValue(scheduleText, columnKey.toString());
          return (
            <Chip
              color={status === 'ACTIVE' ? 'success' : status === 'PAUSED' ? 'warning' : 'danger'}
              variant="flat"
              size="sm"
            >
              {status}
            </Chip>
          );
        case 'lastRunAt':
          return (
            <Chip
              color={getKeyValue(scheduleText, columnKey.toString()) ? 'success' : 'default'}
              variant="flat"
              size="sm"
            >
              {getKeyValue(scheduleText, columnKey.toString()) ? 'Sent' : 'Not Sent'}
            </Chip>
          );
        case 'firstSendAt':
          return getKeyValue(scheduleText, columnKey.toString())
            ? dayjs(getKeyValue(scheduleText, columnKey.toString())).format('MMM D, YYYY hh:mm a')
            : 'N/A';
        case 'actions':
          const wasSent = !!getKeyValue(scheduleText, 'lastRunAt');
          console.log(wasSent);
          return (
            <div className="flex gap-2">
              <Tooltip content="Edit">
                <Edit
                  color="secondary"
                  {...(wasSent
                    ? {
                        style: { cursor: 'pointer' },
                        onClick: () => navigate(`/scheduled-texts/${scheduleText.id}`),
                      }
                    : {
                        style: { cursor: 'not-allowed' },
                      })}
                />
              </Tooltip>
              <Tooltip content="Duplicate">
                <CopyAll
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/scheduled-texts/new?duplicate=${scheduleText.id}`)}
                />
              </Tooltip>
              <Tooltip content={scheduleText.messageTemplate} size="sm" className="max-w-[300px]">
                <Textsms color="secondary" />
              </Tooltip>
            </div>
          );

        default:
          return getKeyValue(scheduleText, columnKey.toString());
      }
    },
    [navigate],
  );

  return {
    isLoading,
    recurring,
    recurringCount,
    nonRecurringCount,
    setRecurring,
    renderRecurringCell,
    renderNonRecurringCell,
    onChangeFilter,
    goToNewScheduledText,
    scheduleTextsList: list,
  };
};
