import PageTitle from '@components/PageTitle';
import { Search } from '@mui/icons-material';
import {
  Button,
  Input,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
} from '@nextui-org/react';
import type { ScheduleTextConfiguration } from '@services/scheduledTexts/types';
import { useScheduledTextsPage } from './useScheduledTextsPage';
import { scheduleTextsNonRecurringColumns, scheduleTextsRecurringColumns } from './utils';

const ScheduleTexts = () => {
  const {
    isLoading,
    recurring,
    recurringCount,
    nonRecurringCount,
    setRecurring,
    onChangeFilter,
    goToNewScheduledText,
    scheduleTextsList,
    renderRecurringCell,
    renderNonRecurringCell,
  } = useScheduledTextsPage();

  return (
    <>
      <PageTitle>Schedule Texts</PageTitle>
      <div className="flex content-center gap-5 my-5">
        <Input
          aria-label="Search"
          size="sm"
          startContent={<Search />}
          onChange={onChangeFilter}
          placeholder="Search..."
          className="w-72"
        />
        <Button
          color="secondary"
          onClick={goToNewScheduledText}
          className="bg-theme-primary text-white"
        >
          New Schedule Text
        </Button>
      </div>
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={(key) => setRecurring(key === 'recurring')}
      >
        <Tab key="recurring" title={`Recurring (${recurringCount})`} />
        <Tab key="nonRecurring" title={`Non Recurring (${nonRecurringCount})`} />
      </Tabs>
      <Table
        aria-label="Schedule Texts table"
        isStriped
        shadow="md"
        bottomContent={null}
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader
          columns={recurring ? scheduleTextsRecurringColumns : scheduleTextsNonRecurringColumns}
        >
          {(column: { key: string; label: string }) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={scheduleTextsList.items as ScheduleTextConfiguration[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: ScheduleTextConfiguration) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>
                  {recurring
                    ? renderRecurringCell(item, columnKey)
                    : renderNonRecurringCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ScheduleTexts;
