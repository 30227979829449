import { apiRoutes } from '@services/constants';

import type {
  GetScheduleTextResponse,
  ListScheduledTextAudiencesResponse,
  ListScheduleTextsResponse,
  ScheduleTextConfiguration,
  ScheduleTextConfigurationWithoutId,
} from '@services/scheduledTexts/types';
import { getToken } from '@utils/auth';
import * as fetch from '@utils/fetch';

export const getScheduleTexts = async (filter?: string, recurring?: boolean) => {
  const params = new URLSearchParams();

  if (filter) {
    params.append('filter', filter);
  }

  if (recurring) {
    params.append('recurring', recurring.toString());
  }

  const response = await fetch.get<ListScheduleTextsResponse>(
    `${apiRoutes.scheduleTexts}?${params.toString()}`,
  );

  return response;
};

export const getScheduleText = (id: string): Promise<GetScheduleTextResponse> =>
  fetch.get<GetScheduleTextResponse>(
    `${apiRoutes.scheduleTexts}/${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const createScheduleText = async (scheduleText: ScheduleTextConfigurationWithoutId) => {
  const response = await fetch.post<
    { scheduledText: ScheduleTextConfigurationWithoutId },
    ScheduleTextConfiguration
  >(apiRoutes.scheduleTexts, { scheduledText: scheduleText });

  return response;
};

export const updateScheduleText = async (
  id: string,
  scheduledText: ScheduleTextConfigurationWithoutId,
) => {
  const response = await fetch.put<
    { scheduledText: ScheduleTextConfigurationWithoutId },
    ScheduleTextConfiguration
  >(
    `${apiRoutes.scheduleTexts}/${id}`,
    { scheduledText },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

  return response;
};

export const getScheduledTextAudiences = (): Promise<ListScheduledTextAudiencesResponse> =>
  fetch.get<ListScheduledTextAudiencesResponse>(
    `${apiRoutes.scheduleTexts}/audiences`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );
