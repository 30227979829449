import type { AgencyObject } from '@services/agencies/types';
import type { MultiValue } from 'react-select';
import ReactSelect from 'react-select';
import type { AgencyOption } from '../Details';

type Props = {
  onChange: (selectedOptions: MultiValue<AgencyOption>) => void;
  agencies: AgencyObject[];
  isLoading: boolean;
  value: MultiValue<AgencyOption>;
};

const mapToAgencyOption = (agencies: AgencyObject[]) =>
  agencies.map((agency) => ({
    value: agency.id.toString(),
    label: `${agency.id} - ${agency.name}`,
    agency,
  }));

const SelectAgency = ({ onChange, agencies, isLoading, value }: Props) => {
  const agencyOptions = mapToAgencyOption(agencies);

  return (
    <div className="flex flex-col gap-2">
      <label className="block text-sm font-medium text-foreground">Agencies</label>
      <ReactSelect<AgencyOption, true>
        isMulti
        options={agencyOptions}
        value={value}
        onChange={onChange}
        placeholder="Search agencies..."
        className="basic-multi-select"
        classNamePrefix="select"
        required
        isLoading={isLoading}
        loadingMessage={() => 'Loading agencies...'}
        noOptionsMessage={() => 'No agencies found'}
        styles={{
          control: (baseStyles: any) => ({
            ...baseStyles,
            minHeight: '48px',
          }),
          menu: (baseStyles: any) => ({
            ...baseStyles,
            zIndex: 9999,
          }),
          loadingMessage: (baseStyles: any) => ({
            ...baseStyles,
            color: '#7828C8',
          }),
          noOptionsMessage: (baseStyles: any) => ({
            ...baseStyles,
            color: '#7828C8',
          }),
        }}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#7828C8',
            primary75: '#9353D3',
            primary50: '#AE7EDE',
            primary25: '#C9A9E9',
          },
        })}
      />
    </div>
  );
};

export default SelectAgency;
