import type { AgencyObject } from '@services/agencies/types';
import type { ScheduleTextConfiguration } from '@services/scheduledTexts/types';

export const scheduleTextsRecurringColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'audience',
    label: 'Audience',
  },
  {
    key: 'recurrencePeriodInDays',
    label: 'Recurrence Period (Days)',
  },
  {
    key: 'firstSendAt',
    label: 'First Send At',
  },
  {
    key: 'lastRunAt',
    label: 'Last Run At',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

export const scheduleTextsNonRecurringColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'audience',
    label: 'Audience',
  },
  {
    key: 'firstSendAt',
    label: 'Scheduled To Send At',
  },
  {
    key: 'lastRunAt',
    label: 'Was Sent?',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

export const getSortedAgencies = (
  agencies: AgencyObject[],
  scheduledTexts: ScheduleTextConfiguration,
) => {
  const selectedAgencies = agencies
    .filter((agency) =>
      scheduledTexts.agency.some((selected) => selected.id.toString() === agency.id.toString()),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const unselectedAgencies = agencies
    .filter(
      (agency) =>
        !scheduledTexts.agency.some((selected) => selected.id.toString() === agency.id.toString()),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return [...selectedAgencies, ...unselectedAgencies];
};
