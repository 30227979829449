const baseURL = process.env.REACT_APP_API_BASE_URL;

export const apiRoutes = {
  login: `${baseURL}/auth/login`,
  agencies: `${baseURL}/agency`,
  users: `${baseURL}/users`,
  smsJob: `${baseURL}/sms`,
  integrations: { getAll: `${baseURL}/integrations/getAll` },
  invoices: {
    getAllPending: `${baseURL}/invoices/get-all-pending`,
    submitAutoPay: `${baseURL}/invoices/submit-auto-pay`,
    getInvoiceArrAging: `${baseURL}/invoices/get-invoice-arr-aging`,
  },
  campaigns: `${baseURL}/campaigns`,
  templates: `${baseURL}/templates`,
  rewards: `${baseURL}/rewards`,
  jobPosts: {
    getAll: `${baseURL}/jobPost/all`,
    updateDeleteStatus: `${baseURL}/jobPost/updateDeleteStatus`,
  },
  processingStatus: `${baseURL}/processing-status`,
  agencyProfileFilter: {
    getAgencyProfileFilterByAgencyId: (agencyId: number) =>
      `${baseURL}/profile-filter/agency/${agencyId}`,
    createAgencyProfileFilter: `${baseURL}/profile-filter`,
    updateAgencyProfileFilter: (id: number) => `${baseURL}/profile-filter/${id}`,
  },
  scheduleTexts: `${baseURL}/scheduled-texts`,
} as const;
