import PageTitle from '@components/PageTitle';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  RadioGroup,
  Select,
  SelectItem,
  Spinner,
  Textarea,
} from '@nextui-org/react';
import CustomRadio from '@pages/Agencies/SmsManager/NewSms/CustomRadio';
import { getAgencies, getAgencyLocationsAndDisciplines } from '@services/agencies';
import type { AgencyObject } from '@services/agencies/types';
import {
  createScheduleText,
  getScheduledTextAudiences,
  getScheduleText,
  updateScheduleText,
} from '@services/scheduledTexts';
import type {
  ScheduledTextAudience,
  ScheduleTextConfiguration,
  TemplateVariable,
} from '@services/scheduledTexts/types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { type MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import SelectAgency from './Components/SelectAgency';
import TagTable from './Components/TagsTable';

export interface AgencyOption {
  value: string;
  label: string;
  agency: AgencyObject;
}

const ScheduleTextDetails = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const duplicate = searchParams.get('duplicate');
  const isNewScheduleText = !id;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [locations, setLocations] = useState<{ id: number; name: string }[]>([]);
  const [disciplines, setDisciplines] = useState<string[]>([]);
  const [scheduleText, setScheduleText] = useState<ScheduleTextConfiguration>({
    id: 0,
    name: '',
    type: 'REENGAGEMENT',
    status: 'INACTIVE',
    messageTemplate: '',
    isRecurring: false,
    recurrencePeriodInDays: 0,
    firstSendAt: new Date(),
    audienceId: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
    disabledAt: null,
    lastRunAt: null,
    agency: [],
    locations: [],
    disciplines: [],
  });
  const [scheduledTextAudiences, setScheduledTextAudiences] = useState<ScheduledTextAudience[]>([]);
  const [messageTemplateVariables, setMessageTemplateVariables] = useState<TemplateVariable[]>([]);

  const [agencies, setAgencies] = useState<AgencyObject[]>([]);
  const [agenciesLoading, setAgenciesLoading] = useState(true);

  useEffect(() => {
    const fetchScheduledTextAudiences = async () => {
      const res = await getScheduledTextAudiences();
      setScheduledTextAudiences(res.scheduledTextAudiences);
      setMessageTemplateVariables(res.scheduledTextAudiences[0].templateVariables);
    };

    fetchScheduledTextAudiences();
  }, []);

  const fetchLocationsAndDisciplines = useCallback(async () => {
    try {
      const selectedAgencyIds = [...new Set(scheduleText.agency.map((agency) => agency.id))];
      const response = await getAgencyLocationsAndDisciplines(selectedAgencyIds);

      setLocations(response.locations || []);
      setDisciplines(response.disciplines || []);
      setScheduleText((prev) => ({
        ...prev,
        locations: [
          ...new Set(
            prev.locations.filter((location) =>
              response.locations.some((l) => l.id === location.id),
            ),
          ),
        ],
        disciplines: [
          ...new Set(
            prev.disciplines.filter((discipline) =>
              response.disciplines.some((d) => d === discipline),
            ),
          ),
        ],
      }));
    } catch (error) {
      console.error('Error fetching locations and disciplines:', error);
      toast.error('Failed to fetch locations and disciplines');
    }
  }, [scheduleText.agency]);

  useEffect(() => {
    fetchLocationsAndDisciplines();
  }, [fetchLocationsAndDisciplines]);

  const fetchScheduleText = useCallback(async () => {
    if (isNewScheduleText && !duplicate) {
      setIsLoading(false);
      return;
    }

    const res = await getScheduleText(id || (duplicate ?? ''));
    if (duplicate) {
      setScheduleText((prev) => ({
        ...prev,
        name: `${res.scheduledText.name} (Copy)`,
        messageTemplate: res.scheduledText.messageTemplate,
        isRecurring: res.scheduledText.isRecurring,
        recurrencePeriodInDays: res.scheduledText.recurrencePeriodInDays,
        audienceId: res.scheduledText.audienceId,
        agency: res.scheduledText.agency,
        status: res.scheduledText.status,
        type: res.scheduledText.type,
      }));
    } else {
      setScheduleText(res.scheduledText);
    }
    setIsLoading(false);
  }, [id, duplicate, isNewScheduleText]);

  useEffect(() => {
    fetchScheduleText();
  }, [fetchScheduleText]);

  const fetchAgencies = useCallback(async () => {
    const agenciesFetched = await getAgencies(0, '200', 'active');

    if (agenciesFetched) {
      setAgencies(agenciesFetched);
      setAgenciesLoading(false);
      return;
    }

    setAgencies([]);
    setAgenciesLoading(false);
  }, []);

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  const onSave = async () => {
    setIsSaving(true);

    try {
      if (isNewScheduleText) {
        await createScheduleText({
          ...scheduleText,
        });
      } else {
        await updateScheduleText(id, {
          ...scheduleText,
        });
      }

      toast.success(`Schedule text ${isNewScheduleText ? 'created' : 'updated'} successfully`);
      navigate('/scheduled-texts');
    } catch (error) {
      console.error(error);
      toast.error(`Failed to ${isNewScheduleText ? 'create' : 'update'} schedule text`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAgencyChange = (selectedOptions: MultiValue<AgencyOption>) => {
    setScheduleText((prev) => ({
      ...prev,
      agency: (selectedOptions || []).map((option) => ({
        id: parseInt(option.value, 10),
        name: option.agency.name,
        publicId: option.agency.publicId,
      })),
    }));
  };

  const handleAudienceChange = (audience: ScheduledTextAudience) => {
    setMessageTemplateVariables(audience.templateVariables);
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLocationsIds = e.target.value
      .split(',')
      .map((agencyLocationId) => parseInt(agencyLocationId, 10))
      .filter(Boolean) as number[];

    setScheduleText({
      ...scheduleText,
      locations: selectedLocationsIds.map((agencyLocationId) => ({
        id: agencyLocationId,
        name: locations.find((l) => l.id === agencyLocationId)?.name || '',
      })),
    });
  };

  const handleDisciplineChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDisciplinesIds = e.target.value.split(',').filter(Boolean) as string[];

    setScheduleText({ ...scheduleText, disciplines: selectedDisciplinesIds });
  };

  if (isLoading) {
    return <Spinner color="secondary" />;
  }

  return (
    <>
      <PageTitle>{id === 'new' ? 'New Schedule Text' : 'Edit Schedule Text'}</PageTitle>
      <div className="grid grid-cols-12 gap-5 my-5">
        <div className="col-span-12">
          <Input
            label="Name"
            value={scheduleText.name}
            onChange={(e) => setScheduleText({ ...scheduleText, name: e.target.value })}
          />
        </div>
        <div className="col-span-12">
          <RadioGroup
            label="Audience"
            value={scheduleText.audienceId.toString()}
            onChange={(e) => {
              setScheduleText({ ...scheduleText, audienceId: parseInt(e.target.value, 10) });
            }}
          >
            {scheduledTextAudiences.map((audience) => (
              <CustomRadio
                key={audience.id}
                value={audience.id.toString()}
                onChangeCapture={() => handleAudienceChange(audience)}
              >
                {audience.name}{' '}
                <span className="text-sm text-gray-500">({audience.description})</span>
              </CustomRadio>
            ))}
          </RadioGroup>
        </div>
        <div className="col-span-12">
          <Card className="w-full">
            <CardHeader className="ml-2">
              <span className="text-lg font-bold">Message</span>
            </CardHeader>
            <CardBody className="pt-0">
              <div className="flex gap-5 justify-stretch items-stretch">
                <Textarea
                  label="Template"
                  labelPlacement="outside"
                  value={scheduleText.messageTemplate}
                  onChange={(e) =>
                    setScheduleText({ ...scheduleText, messageTemplate: e.target.value })
                  }
                  minRows={10}
                  required
                />
                <TagTable tags={messageTemplateVariables} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-6">
          <Select
            label="Status"
            selectedKeys={[scheduleText.status]}
            onChange={(e) =>
              setScheduleText({
                ...scheduleText,
                status: e.target.value as ScheduleTextConfiguration['status'],
              })
            }
          >
            <SelectItem key="ACTIVE" value="ACTIVE">
              Active
            </SelectItem>
            <SelectItem key="INACTIVE" value="INACTIVE">
              Inactive
            </SelectItem>
          </Select>
        </div>
        <div className="col-span-6">
          <Select
            label="Type"
            selectedKeys={[scheduleText.type]}
            onChange={(e) =>
              setScheduleText({
                ...scheduleText,
                type: e.target.value as ScheduleTextConfiguration['type'],
              })
            }
          >
            <SelectItem key="REENGAGEMENT" value="REENGAGEMENT">
              Reengagement
            </SelectItem>
            <SelectItem key="ANNOUNCEMENT" value="ANNOUNCEMENT">
              Announcement
            </SelectItem>
            <SelectItem key="MARKETING" value="MARKETING">
              Marketing
            </SelectItem>
          </Select>
        </div>
        <div className="col-span-4">
          <Select
            label="Is Recurring"
            selectedKeys={[scheduleText.isRecurring.toString()]}
            onChange={(e) =>
              setScheduleText({ ...scheduleText, isRecurring: e.target.value === 'true' })
            }
          >
            <SelectItem key="true" value="true">
              Yes
            </SelectItem>
            <SelectItem key="false" value="false">
              No
            </SelectItem>
          </Select>
        </div>
        {scheduleText.isRecurring && (
          <div className="col-span-4">
            <Input
              type="number"
              label="Recurrence Period (Days)"
              value={scheduleText.recurrencePeriodInDays.toString()}
              onChange={(e) =>
                setScheduleText({
                  ...scheduleText,
                  recurrencePeriodInDays: parseInt(e.target.value, 10),
                })
              }
            />
          </div>
        )}
        <div className={scheduleText.isRecurring ? 'col-span-4' : 'col-span-8'}>
          <Input
            type="datetime-local"
            label={scheduleText.isRecurring ? 'First Send At' : 'Send At'}
            value={new Date(scheduleText.firstSendAt).toISOString().slice(0, 16)}
            onChange={(e) =>
              setScheduleText({ ...scheduleText, firstSendAt: new Date(e.target.value) })
            }
          />
        </div>
        <div className="col-span-12">
          <SelectAgency
            onChange={handleAgencyChange}
            agencies={agencies}
            value={agencies
              .map((agency) => ({
                value: agency.id.toString(),
                label: `${agency.id} - ${agency.name}`,
                agency,
              }))
              .filter((option) =>
                scheduleText.agency.some((a) => a.id.toString() === option.value),
              )}
            isLoading={agenciesLoading}
          />
        </div>
        <div className="col-span-12">
          <Select
            label="Locations"
            labelPlacement="outside"
            selectionMode="multiple"
            selectedKeys={scheduleText.locations
              .filter((location) => locations.some((l) => l.id === location.id))
              .map((location) => location.id.toString())}
            onChange={handleLocationChange}
          >
            {locations.map((location) => (
              <SelectItem key={location.id} value={location.id.toString()}>
                {location.name}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="col-span-12">
          <Select
            label="Disciplines"
            labelPlacement="outside"
            selectionMode="multiple"
            selectedKeys={scheduleText.disciplines
              .filter((discipline) => disciplines.some((d) => d === discipline))
              .map((discipline) => discipline)}
            onChange={handleDisciplineChange}
          >
            {disciplines.map((discipline) => (
              <SelectItem key={discipline} value={discipline}>
                {discipline}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="col-span-8 flex gap-5">
          <Button
            color="secondary"
            onClick={onSave}
            isLoading={isSaving}
            className="bg-theme-primary text-white w-60"
          >
            Save
          </Button>
          <Button onClick={() => navigate('/scheduled-texts')} className="w-60">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default ScheduleTextDetails;
