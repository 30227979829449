import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';
import type {
  AgenciesResponse,
  AgencyDetailsObject,
  AgencyLocationsAndDisciplinesResponse,
  AgencyLoginResponse,
  AgencyObject,
} from './types';

export const getAgencies = async (
  cursor: any,
  limit: string,
  key: string,
  filter?: string,
): Promise<AgencyObject[]> => {
  const response = await fetch.get<AgenciesResponse>(apiRoutes.agencies, {
    page: cursor,
    limit,
    filter: filter || '',
    status: key,
  });

  return response.items;
};

export const getAgencyDetails = async (agencyId: string): Promise<AgencyDetailsObject> => {
  const response = await fetch.get<AgencyDetailsObject>(`${apiRoutes.agencies}/${agencyId}`);

  return response;
};

export const loginWithAgency = async (agencyId: number): Promise<AgencyLoginResponse> => {
  const response = await fetch.get<AgencyLoginResponse>(`${apiRoutes.agencies}/${agencyId}/login`);

  return response;
};

export const loginAsCaregiver = async (agencyId: number): Promise<AgencyLoginResponse> => {
  const response = await fetch.get<AgencyLoginResponse>(
    `${apiRoutes.agencies}/${agencyId}/caregiver-login`,
  );

  return response;
};

export const getAgencyLocationsAndDisciplines = async (
  selectedAgencyIds: number[],
): Promise<AgencyLocationsAndDisciplinesResponse> => {
  if (selectedAgencyIds.length === 0) {
    return {
      locations: [],
      disciplines: [],
    };
  }

  const response = await fetch.get<AgencyLocationsAndDisciplinesResponse>(
    `${apiRoutes.agencies}/locations-and-disciplines?agencyIds=${selectedAgencyIds.join(',')}`,
  );

  return response;
};
